import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { Card, CardContent, Typography, Chip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';

const useStyles = makeStyles({
    card: {
        width: '250px',
        height: '200px',
        margin: '16px',
        textDecoration: 'none',
        perspective: '1000px',
        backgroundColor: 'transparent',
    },
    cardInner: {
        position: 'relative',
        width: '100%',
        height: '100%',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
    },
    cardHover: {
        '&:hover $cardInner': {
            transform: 'rotateY(180deg)',
        },
    },
    cardFront: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: '16px',
        boxSizing: 'border-box',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    cardBack: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background for the back side
        color: 'white', // Text color for the back side
        padding: '16px',
        boxSizing: 'border-box',
        transform: 'rotateY(180deg)',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    cardTitle: {
        fontSize: '1.2rem',
        color: '#2d7280',
    },
    cardKind: {
        fontSize: '1rem',
        color: '#555',
    },
    cardTags: {
        marginTop: '8px',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '4px',
    },
    cardDescription: {
        marginTop: '8px',
        fontSize: '0.875rem',
        color: '#fff',
    },
    cardOwner: {
        marginTop: '8px',
        fontSize: '0.875rem',
        color: '#fff',
    },
    filterButtons: {
        marginBottom: '16px',
        '& > *': {
            margin: '0 8px',
            backgroundColor: '#f0f0f0',
            border: '1px solid #2d7280',
            color: '#2d7280',
            '&:hover': {
                backgroundColor: '#e0e0e0',
            },

        },
    },
    activeButton: {
        backgroundColor: '#2d7280 !important',
        color: 'white !important',
    },
});

export const CatalogList = () => {
    const classes = useStyles();
    const catalogApi = useApi(catalogApiRef);
    const [catalogItems, setCatalogItems] = useState<Entity[]>([]);
    const [filter, setFilter] = useState<string | null>(null);

    useEffect(() => {
        const fetchCatalogItems = async () => {
            const response = await catalogApi.getEntities();
            const filteredItems = response.items.filter(
                item => item.kind !== 'Location' && item.kind !== 'Template' && item.kind !== 'Group' && item.kind !== 'User' && item.kind !== 'API'
            );
            setCatalogItems(filteredItems);
        };
        fetchCatalogItems();
    }, [catalogApi]);

    const handleFilterChange = (newFilter: string | null) => {
        setFilter(newFilter === filter ? null : newFilter);
    };

    const filteredItems = filter
        ? catalogItems.filter(item => item.kind.toLowerCase() === filter)
        : catalogItems;

    return (
        <div style={{ padding: '0 20px', textAlign: 'center' }}>
            <h2>Service Catalog</h2>
            <div className={classes.filterButtons}>
                <Button
                    variant="contained"
                    className={filter === 'domain' ? classes.activeButton : ''}
                    onClick={() => handleFilterChange('domain')}
                >
                    Domain
                </Button>
                <Button
                    variant="contained"
                    className={filter === 'system' ? classes.activeButton : ''}
                    onClick={() => handleFilterChange('system')}
                >
                    System
                </Button>
                <Button
                    variant="contained"
                    className={filter === 'component' ? classes.activeButton : ''}
                    onClick={() => handleFilterChange('component')}
                >
                    Component
                </Button>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {filteredItems.map(item => (
                    <Link to={`/catalog/${item.metadata.namespace}/${item.kind.toLowerCase()}/${item.metadata.name}`} key={item.metadata.uid} className={`${classes.card} ${classes.cardHover}`} >
                        <div className={classes.cardInner}>
                            <div className={classes.cardFront}>
                                <Typography variant="h2" component="div" className={classes.cardTitle}>
                                    {item.metadata.name}
                                </Typography>
                                <Typography variant="body2" component="div" className={classes.cardKind}>
                                    {item.kind}
                                </Typography>
                                <div className={classes.cardTags}>
                                    {item.metadata.tags?.map(tag => (
                                        <Chip key={tag} label={tag} size="small" />
                                    ))}
                                </div>
                            </div>
                            <div className={classes.cardBack}>
                                <Typography variant="body2" component="div" className={classes.cardDescription}>
                                    {item.metadata.description}
                                </Typography>
                                <Typography variant="body2" component="div" className={classes.cardOwner}>
                                    Owner: {typeof item.spec?.owner === 'string' ? item.spec.owner : 'Unknown'}
                                </Typography>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};