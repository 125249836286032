import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
  } from '@backstage/theme';
  
  export const ajeraDarkTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.dark,
        primary: {
          main: '#9C93FF',
        },
        secondary: {
          main: '#50E1E8',
        },

        background: {
          default: '#272052',
          paper: '#16122E',
        },
        banner: {
          info: '#57A6F4',
          error: '#F25A40',
          text: '#1E1918',
          link: '#377EF7',
        },

        navigation: {
          background: '#16122E',
          indicator: '#2f8488',
          color: '#EDEDED',
          selectedColor: '#35b8a8',
          navItem: {
            hoverBackground: '#2f8488'
          },         
        },
      }
      
    }),
    fontFamily: 'LatoTimes New Roman',
    defaultPageTheme: 'home',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#120F26', '#120F26'], shape: shapes.wave }),
      documentation: genPageTheme({
        colors: ['#120F26', '#120F26' ],
        shape: shapes.wave2,
        options: { fontColor: '#D6D6D6' },              
      }),
      tool: genPageTheme({ colors: ['#231d49', '#2f8488'], shape: shapes.round }),
      service: genPageTheme({
        colors: ['#6554D4', '#6554D4'],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: ['#8c4351', '#343b58'],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: ['#8c4351', '#343b58'],
        shape: shapes.wave,
      }),
      other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#231d49', '#2f8488'], shape: shapes.wave }),
      
    },
  });