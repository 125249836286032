import React from 'react';
// import { makeStyles } from '@material-ui/core';
import AjeraLogoFull from './logo/small-logo.png';
// const useStyles = makeStyles({
//   svg: {
//     width: 'auto',
//     height: 30,
//   },
//   path: {
//     fill: '#baedc3',
//   },
// });
const LogoFull = () => {
  return <img src={AjeraLogoFull} style={{height: 72 + 'px'}}/>;
  // return (
  //   <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="60px" viewBox="0 0 256.000000 256.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#2a4f69" stroke="none"> <path d="M297 2550 l-239 -5 -23 -27 c-15 -18 -26 -46 -30 -80 -3 -29 -5 -270 -3 -536 l3 -484 33 -29 32 -29 236 0 235 0 -3 -77 -3 -78 -236 -3 c-231 -2 -237 -3 -261 -25 -20 -19 -26 -37 -32 -93 -8 -82 -8 -889 1 -955 3 -26 15 -59 28 -75 l23 -29 309 -7 c169 -3 396 -9 503 -13 188 -7 197 -7 238 14 69 35 73 51 79 317 l6 235 86 -3 86 -3 5 -240 c6 -270 7 -277 80 -309 37 -16 62 -17 228 -12 103 3 322 8 487 11 168 4 312 11 327 16 14 6 31 23 37 37 6 16 13 134 15 277 3 138 8 334 12 438 6 174 0 266 -23 366 -11 44 -52 51 -293 51 l-220 0 0 80 0 80 226 0 c228 0 257 4 279 39 33 54 35 87 35 549 0 401 -2 470 -16 513 -30 91 5 85 -566 94 -529 7 -542 6 -580 -37 -10 -12 -19 -21 -20 -22 -1 -1 -4 -111 -7 -246 l-6 -245 -85 0 -85 0 -5 235 c-4 210 -7 238 -24 267 -11 18 -33 37 -49 43 -31 10 -367 11 -820 0z m1978 -269 c50 -28 54 -57 54 -383 l1 -298 -231 0 c-140 0 -238 -4 -250 -10 -17 -9 -19 -22 -19 -103 0 -59 -4 -97 -12 -105 -9 -9 -74 -12 -230 -12 -217 0 -218 0 -223 23 -3 12 -4 108 -3 212 l3 190 75 6 c90 7 107 14 124 48 21 42 24 67 32 261 4 102 10 187 14 190 5 3 149 4 322 2 275 -3 317 -5 343 -21z m-1314 -148 c1 -216 8 -266 42 -301 24 -24 36 -27 107 -30 l80 -4 0 -209 0 -209 -227 2 -228 3 -3 90 c-4 132 12 125 -269 125 l-234 0 3 318 c2 174 7 325 12 334 20 42 65 47 399 47 l317 1 1 -167z m229 -1143 c0 -110 1 -203 3 -207 1 -5 -33 -10 -76 -14 -70 -5 -81 -8 -109 -37 -28 -28 -33 -41 -39 -104 -4 -40 -8 -140 -8 -221 l-1 -149 -332 7 c-368 7 -374 8 -390 73 -5 17 -7 167 -6 333 l3 301 227 -1 c279 -2 268 -8 268 129 l0 90 230 0 230 0 0 -200z m640 101 c0 -69 4 -102 13 -108 6 -4 118 -10 247 -13 l235 -5 0 -322 0 -323 -28 -27 -27 -28 -338 0 -337 0 -6 192 c-4 105 -9 206 -13 225 -10 54 -53 79 -145 87 l-76 6 2 35 c0 19 1 113 2 208 l1 172 235 0 235 0 0 -99z"/> </g> </svg> 
  // );
  // const classes = useStyles();

  // return (
  //   <svg
  //     className={classes.svg}
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 256.000000 256.000000"
  //   >
  //     <path
  //       className={classes.path}
  //       d="M297 2550 l-239 -5 -23 -27 c-15 -18 -26 -46 -30 -80 -3 -29 -5 -270 -3 -536 l3 -484 33 -29 32 -29 236 0 235 0 -3 -77 -3 -78 -236 -3 c-231 -2 -237 -3 -261 -25 -20 -19 -26 -37 -32 -93 -8 -82 -8 -889 1 -955 3 -26 15 -59 28 -75 l23 -29 309 -7 c169 -3 396 -9 503 -13 188 -7 197 -7 238 14 69 35 73 51 79 317 l6 235 86 -3 86 -3 5 -240 c6 -270 7 -277 80 -309 37 -16 62 -17 228 -12 103 3 322 8 487 11 168 4 312 11 327 16 14 6 31 23 37 37 6 16 13 134 15 277 3 138 8 334 12 438 6 174 0 266 -23 366 -11 44 -52 51 -293 51 l-220 0 0 80 0 80 226 0 c228 0 257 4 279 39 33 54 35 87 35 549 0 401 -2 470 -16 513 -30 91 5 85 -566 94 -529 7 -542 6 -580 -37 -10 -12 -19 -21 -20 -22 -1 -1 -4 -111 -7 -246 l-6 -245 -85 0 -85 0 -5 235 c-4 210 -7 238 -24 267 -11 18 -33 37 -49 43 -31 10 -367 11 -820 0z m1978 -269 c50 -28 54 -57 54 -383 l1 -298 -231 0 c-140 0 -238 -4 -250 -10 -17 -9 -19 -22 -19 -103 0 -59 -4 -97 -12 -105 -9 -9 -74 -12 -230 -12 -217 0 -218 0 -223 23 -3 12 -4 108 -3 212 l3 190 75 6 c90 7 107 14 124 48 21 42 24 67 32 261 4 102 10 187 14 190 5 3 149 4 322 2 275 -3 317 -5 343 -21z m-1314 -148 c1 -216 8 -266 42 -301 24 -24 36 -27 107 -30 l80 -4 0 -209 0 -209 -227 2 -228 3 -3 90 c-4 132 12 125 -269 125 l-234 0 3 318 c2 174 7 325 12 334 20 42 65 47 399 47 l317 1 1 -167z m229 -1143 c0 -110 1 -203 3 -207 1 -5 -33 -10 -76 -14 -70 -5 -81 -8 -109 -37 -28 -28 -33 -41 -39 -104 -4 -40 -8 -140 -8 -221 l-1 -149 -332 7 c-368 7 -374 8 -390 73 -5 17 -7 167 -6 333 l3 301 227 -1 c279 -2 268 -8 268 129 l0 90 230 0 230 0 0 -200z m640 101 c0 -69 4 -102 13 -108 6 -4 118 -10 247 -13 l235 -5 0 -322 0 -323 -28 -27 -27 -28 -338 0 -337 0 -6 192 c-4 105 -9 206 -13 225 -10 54 -53 79 -145 87 l-76 6 2 35 c0 19 1 113 2 208 l1 172 235 0 235 0 0 -99z"
  //     />
  //   </svg>
  // );
};

export default LogoFull;
