import {
    createBaseThemeOptions,
    createUnifiedTheme,
    defaultTypography,
    genPageTheme,
    palettes,
    shapes,
  } from '@backstage/theme';
  
  export const ajeraTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: '#231d49',
        },
        secondary: {
          main: '#2f8488',
        },
        error: {
          main: '#8c4351',
        },
        warning: {
          main: '#8f5e15',
        },
        info: {
          main: '#34548a',
        },
        success: {
          main: '#35b8a8',
        },
        background: {
          default: '#fcfcfc',
          paper: '#fcfcfc',
        },
        banner: {
          info: '#57A6F4',
          error: '#F25A40',
          text: '#1E1918',
          link: '#377EF7',
        },
        errorBackground: '#F25A40',
        warningBackground: '#8f5e15',
        infoBackground: '#57A6F4',
        navigation: {
          background: '#16122E',
          indicator: '#2f8488',
          color: '#fcfcfc',
          selectedColor: '#35b8a8',
          navItem: {
            hoverBackground: '#2f8488'
          },         
        },
      },
    }),
    fontFamily: 'LatoTimes New Roman',
    defaultPageTheme: 'home',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#16122E', '#231d49'], shape: shapes.wave }),
      documentation: genPageTheme({
        colors: ['#20595C', '#2f8488'],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({ colors: ['#231d49', '#2f8488'], shape: shapes.round }),
      service: genPageTheme({
        colors: ['#231d49', '#231d49'],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: ['#8c4351', '#343b58'],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: ['#8c4351', '#343b58'],
        shape: shapes.wave,
      }),
      other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#231d49', '#2f8488'], shape: shapes.wave }),
    },
    typography: {
      ...defaultTypography,
      htmlFontSize: 16,
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
        // fontFamily: 'tahoma',
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
        // fontFamily: 'tahoma',
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      // h4: {
      //   fontWeight: 700,
      //   fontSize: 28,
      //   marginBottom: 6,
      // },
      // h5: {
      //   fontWeight: 700,
      //   fontSize: 24,
      //   marginBottom: 4,
      // },
      // h6: {
      //   fontWeight: 700,
      //   fontSize: 20,
      //   marginBottom: 2,
      // },
    },
  //   components: {
  //   BackstageHeader: {
  //     styleOverrides: {
  //       header: ({ theme }) => ({
  //         // padding: theme.spacing(3),
  //         width: 'auto',
  //         margin: '20px',
  //         // boxShadow: '0 0 8px 3px rgba(20, 20, 20, 0.3)',
  //         boxShadow: 'none',
  //         borderBottom: `4px solid ${theme.palette.primary.dark}`,
  //         backgroundImage: theme.page.backgroundImage,
  //       }),
  //     },
  //   },
  // },
  });