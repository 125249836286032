import React, { useEffect, useState } from 'react';
import { SelfServicesList } from './SelfServicesList';
import { CatalogList } from './CatalogList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  messageContainer: {
    display: 'inline-flex',
    position: 'relative',
    height: '1.5em', // Adjust based on your font size
    // overflow: 'hidden',
    verticalAlign: 'middle',
  },
  message: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    opacity: 0,
    transition: 'opacity 1s ease-in-out, color 1s ease-in-out',
  },
  messageVisible: {
    opacity: 1,
  },
  '@keyframes colorChange': {
    '0%': { color: '#2d7280' },
    '33%': { color: '#7E60BF' },
    '66%': { color: '#B03052' },
    '100%': { color: '#6A42C2' },
  },
  colorAnimation: {
    animation: '$colorChange 12s infinite',
  },
  divider: {
    margin: '40px 0',
    border: 'none',
    borderTop: '2px solid #2d7280',
  },
});


const messages = [
  ' streamline your product management',
  ' improve team collaboration',
  ' boost productivity',
  ' compliance with standards',
  ' automate your workflows',
  ' enhance time to market',
  ' improve software quality',

];

export const HomePage = () => {
  const classes = useStyles();
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [displayedMessage, setDisplayedMessage] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000); // Change message every 9 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const words = messages[currentMessageIndex].split(' ');
    let wordIndex = 0;
    setDisplayedMessage('');

    const wordInterval = setInterval(() => {
      setDisplayedMessage((prev) => prev + (wordIndex > 0 ? ' ' : '') + words[wordIndex]);
      wordIndex += 1;

      if (wordIndex === words.length - 1) {
        clearInterval(wordInterval);
      }
    }, 300); // Add a word every second

    return () => clearInterval(wordInterval);
  }, [currentMessageIndex]);

  return (
    /* We will shortly compose a pretty homepage here. */
    <div>
      {/* <h1 style={{ color: '#231d49', fontSize: '36px', textAlign: 'center', margin: '20px 0', animation: 'fadeIn 1s' }}>
      Welcome to Ajera!
    </h1> */}
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <h2 style={{ color: '#2d7280', animation: 'fadeIn 2s' }}>
          Discover the Power of Ajera!
        </h2>
        <h3>
          AJera helps you {' '}
          <span className={classes.messageContainer}>
            <span className={`${classes.message} ${classes.messageVisible} ${classes.colorAnimation}`}>
              {displayedMessage}
            </span>
          </span>
        </h3>
        {/* <img src="https://via.placeholder.com/150" alt="Ajera Logo" style={{ animation: 'fadeIn 4s' }} /> */}
      </div>

      <hr className={classes.divider} />

      <SelfServicesList />
      {/* <ToolsList /> */}
      <CatalogList />
    </div>
  );
};