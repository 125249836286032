import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        width: '200px',
        textDecoration: 'none',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.3s',
        },
    },
    cardMedia: {
        // height: '140px',
        // height: '50%', // Half the height of the card
        width: '80%', // 80% of the card width
        margin: '0 auto', // Center the image horizontally
        padding: '8px',
        // opacity: '0.8',
    },
    cardContent: {
        padding: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '50%', // Half the height of the card
    },
    cardTitle: {
        fontSize: '1rem',
        color: '#2d7280',
        // transition: 'color 0.3s, transform 0.3s',
        // '&:hover': {
        //     color: 'green',
        //     transform: 'scale(1.05)',
        // },
    },
});

export const SelfServicesList = () => {
    const classes = useStyles();
    const catalogApi = useApi(catalogApiRef);
    const [services, setServices] = useState<Entity[]>([]);

    useEffect(() => {
        const fetchServices = async () => {
            // const response = await catalogApi.getEntities({ filter: { kind: 'Template', 'spec.type': 'service' } });
            const response = await catalogApi.getEntities({ filter: { kind: 'Template' } });
            setServices(response.items);
        };
        fetchServices();
    }, [catalogApi]);

    return (
        <div style={{ padding: '0 20px', textAlign: 'center' }}>
            <h2>Self Services</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {services.map(service => (
                    <Link to={`create/templates/${service.metadata.namespace + '/' + service.metadata.name}`} key={service.metadata.uid} className={classes.card}>
                        <Card key={service.metadata.uid} style={{ width: '200px', height: '250px' }}>
                            <CardMedia
                                className={classes.cardMedia}
                                component="img"
                                alt={service.metadata.name}
                                image={service.metadata.annotations?.['ajera.io/image-url'] || 'https://raw.githubusercontent.com/ajera-sa/catalog/main/images/self-service.gif'}
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="div" className={classes.cardTitle}>
                                    {service.metadata.title || service.metadata.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                ))}
            </div>
        </div>
    );
};